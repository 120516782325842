import { GlobalStyles } from '@gradehub/gooseberry'
import type { AppProps } from 'next/app'
import React from 'react'
import styled from 'styled-components'

const StyledContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100vh;
`

const MyApp = (
    {
        Component,
        pageProps
    }: AppProps
): React.ReactNode => {
    return <div>
        <GlobalStyles/>
        <StyledContent>
            <Component {...pageProps} />
        </StyledContent>
    </div>
}

export default MyApp
